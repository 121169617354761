@import "../../assets/globals";

.ReactModal {
  &__Overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 100000;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    background-color: rgba(0,0,0,0.6) !important;

    &--after-open {
      opacity:1
    }
    &--before-close {
      opacity: 0;
    }
  }
  &__Content {
    margin: auto;
    inset: 0 !important;
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;

    &.language {
      overflow: unset;
      display: flex;
      flex-direction: column;

      width: 80%;
      height: 330px;
    }

    &.subscription-limit {
      max-width: 600px;
      height: 550px;
      display: flex;
      flex-direction: column;

      .image {
        max-width: 180px;
        margin: 1.5rem auto 2rem auto;
      }
    }

    .head {
      display: flex;
      flex-direction: row;

      .title {
        flex:1;
      }

      .close {
        cursor: pointer;

        span {
          padding: 4px;
          text-align: center;
        }
      }
    }
  }

}

@media screen and (min-width: 768px) {
  .ReactModal__Content {
    &.language {
      max-height: 400px;
      max-width: 500px;
    }
  }
}
