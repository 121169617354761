@import "../../assets/globals";

.selector {

  &__singleValue {

  }

  &__control {
    border-color: $border-color-1 !important;
    border-radius: 0.5rem !important;
    font-size: 0.90rem;
    line-height: 1rem;

    &:hover {
      border-color: $primary-color !important;
    }

    &.selector__control--is-focused {
      border-color: $primary-color !important;
      box-shadow: unset;
    }
  }

  &__option {
    color: $main-text-color !important;

    &--is-focused {
      background-color: $border-color-1 !important;
    }

    &--is-selected {
      background-color: $primary-color !important;
      color: #ffffff !important;
    }

  }

  &__menu {
    position: absolute;
    z-index: 1000000;
  }



}
