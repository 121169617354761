$main-text-color: rgb(37,41,46);

$main-bg-color: rgb(248,249,250);
$light-bg-color: #ffffff;


$primary-color: #3FCAB2;
$primary-hover-color: #2a8878;

$secondary-color: #5CACF1;
$secondary-hover-color: #4686bd;


$light-color: rgb(241, 243, 245);
$light-hover-color: rgb(225, 227, 229);

$border-color-1: rgb(229,231,235);

$label-color: rgb(68, 68, 68);
$placeholder-color: #d2d2d2;


$favorite-color: rgb(203, 49, 49);

$input-disabled-background: rgb(241, 243, 245);

$offer-red-color: rgb(234, 2, 2);

$red-alert-color: #e10404;

//Dimensions
$navbar-height: 3.6rem;
$left-bar-width: 220px;