@import "../../assets/globals";

.login-screen {
  max-width: 490px;
  margin: 2rem auto 0 auto;
  padding: 0 10px 0 10px;

  .form {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid $border-color-1;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,.1),0 3px 6px rgba(0,0,0,.05);
  }
}