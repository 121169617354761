@import "../../assets/globals";

.layout-guest {
  background-color: $main-bg-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .cont {
    padding-top: $navbar-height;
  }
}