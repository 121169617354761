@import "../../assets/globals";

.s-tool-cont {
  display: flex;
  flex:1;

  .inner {
    display: flex;
    flex-direction: column;
    flex:1;
    max-height: calc(100vh - #{$navbar-height});
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .right-bar {
    border-left: 1px solid $border-color-1;
    background-color: $light-bg-color;
    max-width: 440px;
    min-width: 440px;
    width: 440px;
    height: 100%;
    padding: 15px;

    .textarea-counter {
      float:right;
      font-size: 0.8rem;
      color: $label-color;
    }

    .textarea-normal {
      min-height: 150px;

      &.small {
        min-height: 100px;
      }
    }

    .step {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;

      .step-label {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
        color: #ffffff;
        font-size: 0.8rem;
        line-height:1rem;
        font-weight: 900;
        text-transform: uppercase;
        padding: 2px 5px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
      }
      .step-description {
        color: $primary-color;
        font-size: 0.9rem;
        line-height: 1rem;
        padding-left: 5px;
      }

    }

    .field-right-icon {
      position:relative;

      input, textarea {
        padding-right: 2.2rem;
      }

      .right-icon {
        position: absolute;
        top:0.4rem;
        right:0.7rem;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .s-tool-cont {
    flex-direction: column;

    .inner {
      max-height: unset;
      overflow-y: unset;
      order: 2;
    }

    .right-bar {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: unset;
      order: 1;
    }

  }

}
