@import "../../../assets/globals";

.preview-cont {
  max-width: 600px;
  margin: 0 auto 0 auto;

  .head {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $border-color-1;
    justify-content: right;
    padding-bottom: 0.1rem;
    margin-bottom: 0.8rem;

    .item {
      margin-left: 2.2rem;
      cursor: pointer;
      font-weight: 500;
      font-size: 0.9rem;

      &:hover {
        color: $primary-hover-color
      }

      &.active {
        color: $primary-color
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .tab {

      .ph {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 400px;

        img {
          width: 100%;
          height: 100%;
          max-width: 220px;
          max-height: 220px;
          margin-bottom: 2rem;
        }
      }
      .view-more-cont {
        text-align: center;
      }
    }

  }
}

@media screen and (min-width: 768px) {
  .preview-cont {
    min-width: 600px;
  }
}