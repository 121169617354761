@import "../../../../../assets/globals";

.standard-preview {
  margin: 1rem auto 1rem auto;
  padding: 1.2rem 1.2rem;
  border: 1px solid $border-color-1;
  border-radius: 5px;
  background-color: #fff;

  .value {
    padding:0 0 0 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top:0.25rem;

    .language {
      flex:1;
      padding-top: 2px;
      user-select: none;
    }

    .btn {
      padding: 0.3rem 0.4rem;
      font-size: 0.8rem;
      line-height: 0.8rem;
      margin-left: 10px;
      border-radius: 4px;

      &.btn-favorite i.active {
          color: $favorite-color;
      }

    }


  }
}
