@import "../../assets/globals";

.subscription {

  .progress {
    height: 1.5rem;
  }

  .cta-pro-title {
    font-weight: 800;
    text-align: center;
    margin: 1rem 0 2rem 0;
  }


}