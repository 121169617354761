@import "../../assets/globals";

.dashboard {
  animation: fadein 0.5s;

  .welcome-icon {
    font-size: 3rem;
  }

  .welcome-text {
    margin-bottom: 1rem;
  }

  .section-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .section-content {
    &.white {
      border: 1px solid rgb(229, 231, 235);
      border-radius: 8px;
      background-color: #ffffff;
      padding: 1rem;
    }
  }

  .tools  {
    .category {
      display: flex;
      flex-direction: column;

      .items {
        display: flex;
        flex:1;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .item {
          display: flex;
          flex-direction: column;
          width: 280px;
          height: 220px;
          border: 1px solid $border-color-1;
          border-radius: 8px;
          background-color: $light-bg-color;
          padding: 1rem;
          margin: 0 1rem 1rem 0;
          cursor: pointer;

          img {
            margin-bottom: 0.8rem;
            width: 45px;
          }

          .title {
            font-weight: 600;
          }
          .description {
            flex:1;
            font-size: 0.85rem;
            margin-top:0.2rem;
            color: $label-color;
          }
          .button {
            text-align: center;
            border: 1px solid $border-color-1;
            padding: 0.4rem 0;
            border-radius: 8px;
            color: $label-color;
            font-size: 0.85rem;
            font-weight: 600;
          }

          &:hover {
            .button {
              background-color: $primary-color;
              color: #ffffff;
            }
          }
        }
      }
    }

  }
}

@media screen and (max-width: 768px) {

  .dashboard {
    .tools {
      .category {
        .items {
          .item {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }

}
