@import "assets/globals";
@font-face {
  font-family: Inter;
  src: url("./assets/font/Inter-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url("./assets/font/Inter-Medium.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url("./assets/font/Inter-SemiBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url("./assets/font/Inter-Bold.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-text-color;
  font-size: 0.95rem;
  letter-spacing: -0.009rem;
}


//Forms
label {
  font-weight: 500;
  letter-spacing: -0.009rem;
  font-size: 0.80rem;
  color: $label-color;
  margin-bottom: 0.1rem;
}

.form-control {
  padding: 0.4rem 0.6rem;
  font-size: 0.90rem;
  line-height: 1.3rem;
  border-radius: 0.5rem;
  border: 1px solid $border-color-1;
  box-shadow: 0 0 0 0 white inset !important;
  -webkit-box-shadow: 0 0 0 0 white inset !important;

  &:hover, &:focus {
    border-color: $primary-color;
    background: transparent !important;
    box-shadow: 0 0 0 0 white inset !important;
    -webkit-box-shadow: 0 0 0 0 white inset !important;
  }

  &:disabled {
    background: $input-disabled-background !important;

    &:hover, &:focus {
      background: $input-disabled-background !important;
      border-color: $border-color-1 !important;
    }
  }


  &::placeholder {
    color: $placeholder-color;
    opacity: 1;
  }

}

.invalid-feedback {
  display: block;
}

//Buttons
.btn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600;

  &:focus {
    outline:0 !important;
    box-shadow: none !important;
  }
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;

  &:hover {
    background-color: $primary-hover-color;
    border-color: $primary-hover-color;
  }

  &:focus {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &:disabled {
    background-color: $primary-color;
    border-color: $primary-color;
    opacity: 0.8;
  }
}

.btn-light {
  background-color: $light-color;
  border-color: $light-color;

  &:hover {
    background-color: $light-hover-color;
    border-color: $light-hover-color;
  }

  &:focus {
    background-color: $light-color;
    border-color: $light-color;
  }

  &:disabled {
    background-color: $light-color;
    border-color: $light-color;
    opacity: 0.8;
  }
}

.btn-secondary {
  background-color: $secondary-color;
  border-color: $secondary-color;

  &:hover {
    background-color: $secondary-hover-color;
    border-color: $secondary-hover-color;
  }

  &:focus {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }

  &:disabled {
    background-color: $secondary-color;
    border-color: $secondary-color;
    opacity: 0.8;
  }
}

.progress-bar {
  background-color: $primary-color;
}

//Links
a {
  color: $primary-color;

  &:hover {
    color: $primary-hover-color;
  }
}

//Headings
h1, .h1 {
  font-weight: 800;
}
h2, .h2 {
  font-weight: 800;
}
h3, .h3 {
  font-weight: 800;
  letter-spacing: -0.017rem;
}

//Languages icons
.fi {
  border-radius:2px;
}

//Cursors
.cursor-pointer {
  cursor: pointer !important;
}


//Animations
@keyframes fadein {
  from { opacity: 0.5; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0.5; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0.5; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0.5; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0.5; }
  to   { opacity: 1; }
}
