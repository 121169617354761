.loader {

  &.big {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 300px;
  }

}