@import "../../assets/globals";

.navbar {
  position: fixed;
  width: 100%;
  height: $navbar-height;
  background-color: $light-bg-color;
  border-bottom: 1px solid $border-color-1;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  z-index: 1000;

  .logo-cont {
    display: flex;
    img {
      height: 1.8rem;
    }
  }

  .right-cont {
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: flex-end;

    .item {
      height: 100%;
      padding: 4px 10px;
      cursor:pointer;
      font-size: 1rem;
      margin-right: 1rem;
      border-radius: 8px;
      border: 1px solid $border-color-1;

      &:hover {
        background-color: $border-color-1;
      }

      &:last-of-type {
        margin-right:0;
      }

      &.lang {

      }

      &.buyPro {
        background-color: $primary-color;
        color: #ffffff;
        border-color: $primary-color;
        padding: 4px 20px;
      }
    }
  }

  .right-cont-mobile {
    display: none;
    font-size: 1.4rem;
    cursor: pointer;
  }

  .mobile-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $light-bg-color;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      height: $navbar-height;
      padding: 0 1rem;
      border-bottom: 1px solid $border-color-1;

      .mobile-logo-cont {
        display: flex;
        flex:1;
        justify-content: flex-start;

        img {
          height: 1.8rem;
        }
      }

      .hamburger {
        font-size: 1.4rem;
      }
    }

    .menu {
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        color: $main-text-color;
      }

      .item {
        padding: 1rem 1rem;
        font-size: 1rem;
        border-bottom: 1px solid $border-color-1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {

    .right-cont {
      display: none;
    }

    .right-cont-mobile {
      display: block;
    }
  }
}
