@import "../../../../../assets/globals";

.brand-name-preview {
  margin: 1rem auto 1rem auto;
  padding: 1.2rem 1.2rem;
  border: 1px solid $border-color-1;
  border-radius: 5px;
  background-color: #fff;

  .items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .item-outer {
      width: 100%;
      padding: 0.4rem 0.4rem;
    }

    .item {
      text-align: center;
      padding: 3rem 0;
      border-radius: 8px;
      font-size: 1.5rem;

      &.key0 {
        background: #182747;
        color: #ffffff;
        font-weight: 900;
        font-family: Arial, serif;
      }
      &.key1 {
        background: rgb(10,88,202);
        background: linear-gradient(52deg, rgba(10,88,202,1) 0%, rgba(10,146,202,1) 100%);
        color: #ffffff;
        font-weight: 900;
        letter-spacing: -1px;
      }
      &.key2 {
        background: #074a65;
        color: #ffffff;
        font-weight: 600;
        font-style: italic;
        font-family: Courier New, serif;
      }
      &.key3 {
        background: rgb(189,19,1);
        background: linear-gradient(90deg, rgba(189,19,1,1) 0%, rgba(255,226,0,1) 100%);
        color: #ffffff;
        font-weight: 600;
        letter-spacing: 1px;
      }
      &.key4 {
        background: rgb(94,202,10);
        background: linear-gradient(146deg, rgba(94,202,10,1) 0%, rgba(10,202,177,1) 100%);
        color: #ffffff;
        font-weight: 900;
        font-style: italic;
      }
      &.key5 {
        background: rgb(13,10,202);
        background: linear-gradient(146deg, rgba(13,10,202,0.7) 0%, rgba(13,10,202,0.8) 100%);
        color: #ffffff;
        font-weight: 600;
        font-family: Courier New, serif;
      }
      &.key6 {
        background: rgb(202,10,140);
        background: linear-gradient(146deg, rgba(202,10,140,1) 0%, rgba(202,10,101,0.6) 100%);
        color: #ffffff;
        font-weight: 900;
      }
      &.key7 {
        background: rgb(181,141,255);
        background: linear-gradient(0deg, rgba(181,141,255,1) 0%, rgba(108,74,182,1) 100%);
        color: #ffffff;
        font-weight: 800;
      }
    }
  }

  .value {
    padding:0 0 0 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top:0.25rem;

    .language {
      flex:1;
      padding-top: 2px;
      user-select: none;
    }

    .btn {
      padding: 0.3rem 0.4rem;
      font-size: 0.8rem;
      line-height: 0.8rem;
      margin-left: 10px;
      border-radius: 4px;

      &.btn-favorite i.active {
          color: $favorite-color;
      }

    }


  }
}

@media screen and (max-width: 768px) {

  .brand-name-preview {
    .items {
      .item-outer {
        width: 100%;
      }
    }
  }

}
