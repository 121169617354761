@import "../../assets/globals";

.thank-you {
  margin-top: 2rem;
  text-align: center;
  padding: 0 2rem;

  .icon {
    color: $primary-color;
    font-size: 10rem;

    &.red {
      color: $red-alert-color;
    }
  }

}