@import "../../assets/globals";

.upgrade-to-pro {

  .items {
    max-width: 900px;
    margin: 0 auto 0 auto;

    .item {
      padding: 1.5rem 1rem;
      border: 1px solid $border-color-1;
      border-radius: 5px;
      margin-bottom: 2rem;

      .title {
        font-size: 1.3rem;
        font-weight: 800;
        text-align: center;
      }

      .old-price {
        margin: 1rem 0 0.2rem 0;
        color: $offer-red-color;
        text-align: center;
        text-decoration: line-through;
        font-size: 0.9rem;
      }
      .price {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;

        margin: 0 0 1rem 0;
        color: $primary-color;

        .value {
          font-size: 2.8rem;
          line-height: 2.8rem;
          font-weight: 600;
        }

        .month {
          font-size: 0.85rem
        }

        .currency {
          font-size: 1.2rem;
          align-self: flex-start;
        }
      }

      .offer {
        max-width: 900px;
        margin: 0 auto 1rem auto;
        font-size: 0.9rem;
        font-weight: 900;
        background-color: $offer-red-color;
        color: #ffffff;
        border: 1px solid $offer-red-color;
        border-radius: 5px;
        padding: 2px 0;
        text-align: center;
      }

      .monthly-limit {
        margin: 1.2rem 0 0 0;
        font-size: 0.9rem;
        font-weight: 600;
      }

      .features {
        margin-top: 0.5rem;
        font-size: 0.9rem;
      }

      .description {
        color: $label-color;
        font-size: 0.8rem;
        line-height: 1.1rem;
        margin: 1rem 0 0 0;
      }
    }
  }

}
