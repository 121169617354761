@import "../../assets/globals";

.layout {
  background-color: $main-bg-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .cont {
    display: flex;
    flex-direction: row;
    flex:1;

    .left-bar {
      position: fixed;
      height: 100vh;
      width: $left-bar-width;
      background-color: $light-bg-color;
      border-right: 1px solid $border-color-1;
      padding-top: $navbar-height;
      z-index: 500;

      .menu {
        padding: 10px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .top-items {
          display: flex;
          flex-direction: column;
          flex:1;
        }

        hr {
          border-color: $border-color-1;
          opacity: 1;
        }

        a {
          text-decoration: none !important;
          color: $main-text-color;
          font-weight: 700;
        }

        .item {
          width: 100%;
          padding: 10px 10px;
          border-radius: 5px;
          margin-bottom: 7px;
          cursor: pointer;
          font-size: 0.95rem;

          &:hover {
            background-color: $light-color;
          }

          &.logout {
            align-self: flex-end;
            justify-self: flex-end;
          }
        }

        a.active .item {
          background-color: $light-color;
        }
      }
    }

    .main-cont {
      display: flex;
      flex:1;
      padding-top: $navbar-height;
      padding-left: $left-bar-width;

      .inner {
        padding: 1.5rem;
        width: 100%;
        animation: fadein 0.5s;
      }

      .inner-cont {
        background-color: $light-bg-color;
        border: 1px solid $border-color-1;
        padding: 1rem;
        border-radius: 5px;
      }
    }

  }
}

@media screen and (max-width: 768px) {
  .layout {

    .cont {
      .left-bar {
        display: none;
        width: 0;
      }

      .main-cont {
        padding-left: 0;

        .inner {
          padding: 1rem
        }
      }
    }

  }
}
